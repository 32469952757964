import { MID_INTERVAL } from 'config/constants'
import { useMiningContract } from 'hooks/useContract'
import { useCallback } from 'react'
import useSWR from 'swr'

const useGetBones = (): any => {
  const contractMethod = useMiningContract()

  const getData = useCallback(async () => {
    if (contractMethod) {
      try {
        const _bones = await contractMethod.BONES()
        if (_bones) return _bones
      } catch (error) {
        console.error('useGetBones', error)
        return null
      }
    }
  }, [contractMethod])

  const { data, mutate } = useSWR(
    'getBones',
    () => {
      return getData()
    },
    {
      refreshInterval: MID_INTERVAL,
      revalidateOnFocus: false,
    },
  )

  return { data, refresh: mutate }
}

export default useGetBones
