import { MID_INTERVAL } from 'config/constants'
import { useMiningContract } from 'hooks/useContract'
import { useEffect, useState, useCallback } from 'react'
import useSWR from 'swr'

const useGetMyMiners = (address: string): any => {
  const contractMethod = useMiningContract()

  const getData = useCallback(async () => {
    if (contractMethod && address) {
      try {
        const myMiner = await contractMethod.getMyMiners(address)
        if (myMiner) return myMiner
      } catch (error) {
        console.error('useGetMyMiners', error)
        return null
      }
    }
  }, [contractMethod, address])

  const getMinBunny = useCallback(
    async (cbs?: (res: any) => void, cbe?: (err: any) => void) => {
      if (contractMethod) {
        try {
          const minBunny = await contractMethod.MIN_BUNNY()
          if (minBunny && cbs) return cbs(minBunny)
        } catch (error) {
          cbe && cbe(error)
          console.error('getMinBunny', error)
        }
      }
    },
    [contractMethod, address],
  )

  const { data, mutate } = useSWR(
    [address, 'getMyMiners'],
    () => {
      return getData()
    },
    {
      refreshInterval: MID_INTERVAL,
      revalidateOnFocus: false,
    },
  )

  return { data, refresh: mutate, getMinBunny }
}

export default useGetMyMiners
