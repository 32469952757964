/* eslint-disable react/button-has-type */
import { Button } from 'antd'
import FormatNumberSmall from 'components/FormatNumberSmall'
import { DEFAULT_GAS_PRICE } from 'config'
import { TOKEN } from 'config/constants/endpoints'
import { useTranslation } from 'contexts/Localization'
import { formatCode } from 'helpers/CommonHelper'
import useGetBeanRewards from 'hooks/bunnyMining/useGetBeanRewards'
import useGetBones from 'hooks/bunnyMining/useGetBones'
import useGetMyMiners from 'hooks/bunnyMining/useGetMyMiners'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { useCallWithGasPrice } from 'hooks/useCallWithGasPrice'
import useCatchTxError from 'hooks/useCatchTxError'
import { useMiningContract } from 'hooks/useContract'
import { useGetUserAffiliate } from 'hooks/useGetUserAffiliate'
import useToast from 'hooks/useToast'
import useTokenBalance, { useGetBnbBalance } from 'hooks/useTokenBalance'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { calculateGasMargin, toLocaleString } from 'utils'
import { MiningContent } from 'views/Mining/styled'
import { setModalConnect } from '../../../state/modal/actions'

const Wrapper = styled.div`
  padding: 100px 0;
  background-color: #ffff0b;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  .top-logo {
    position: absolute;
    top: -30px;
    left: 50%;
    transform: translateX(-50%);
    width: 124px;
    z-index: 1;
  }
`

const StartMine = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { toastError, toastSuccess } = useToast()
  const { account } = useActiveWeb3React()

  const contractMethod = useMiningContract()
  const { callWithGasPrice } = useCallWithGasPrice()
  const { fetchWithCatchTxError } = useCatchTxError()

  const { data: userAffiliate, isLoading: loadingRef } = useGetUserAffiliate(account)
  const { balance, refresh: refreshBalance } = useGetBnbBalance()
  const { balance: bunnyBalance, mutate: refreshBunnyBalance } = useTokenBalance(TOKEN)
  const { data: bones, refresh: refreshBones } = useGetBones()
  const { data: myMiner, getMinBunny, refresh: refreshMyMiner } = useGetMyMiners(account)
  const { data: beanRewards, refresh: refreshBeanRewards } = useGetBeanRewards(account)

  const [loading, setLoading] = useState(false)
  const [amount, setAmount] = useState(undefined)
  const [minBunny, setMinBunny] = useState(undefined)
  const [miningSpeed, setMiningSpeed] = useState(0)

  const renderInputStatus = () => {
    if (!account) return 'Connect wallet'
    if (!balance || !minBunny || !bunnyBalance) return 'Loading balance'
    if (+balance / 1e18 < 0.01) return 'Influence balance'
    if (bunnyBalance && minBunny && +bunnyBalance / 1e18 < +minBunny / 1e18)
      return `${t('Hold at least')} ${+minBunny / 1e18} Bunny`
    if (!amount || +amount === 0) return 'Enter amount'
    if (amount && +amount > +balance / 1e18) return 'Influence balance'
    return 'Hire miners'
  }

  const toggleWallet = () => {
    dispatch(setModalConnect({ toggle: true }))
  }

  const handleChangeAmount = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAmount(e?.target?.value)
  }

  const handleSuccess = async () => {
    await refreshBalance()
    await refreshBones()
    await refreshBunnyBalance()
    setLoading(false)
  }

  const handleBuyBones = async () => {
    const payload = {
      account,
      amount: toLocaleString(+amount * 1e18),
      ref: userAffiliate?.data?.tree?.refAddress,
    }

    try {
      setLoading(true)
      const estimatedGas = await contractMethod.estimateGas.buyBones(payload.ref, {
        value: payload.amount,
      })

      const receipt = await fetchWithCatchTxError(() => {
        return callWithGasPrice(contractMethod, 'buyBones', [payload.ref], {
          value: payload.amount,
          gasPrice: DEFAULT_GAS_PRICE,
          gasLimit: calculateGasMargin(estimatedGas),
        })
      })

      if (receipt?.status) {
        toastSuccess('Hire miners successfully')
        await handleSuccess()
        await refreshMyMiner()
        await refreshBeanRewards()
        setAmount(0)
      } else {
        setLoading(false)
      }
    } catch (error: any) {
      setLoading(false)
      toastError(error?.message || error?.data?.message || 'Hires miner has error')
    }
  }

  const handleSellBones = async () => {
    try {
      setLoading(true)
      const estimatedGas = await contractMethod.estimateGas.sellBones()

      const receipt = await fetchWithCatchTxError(() => {
        return callWithGasPrice(contractMethod, 'sellBones', undefined, {
          gasPrice: DEFAULT_GAS_PRICE,
          gasLimit: calculateGasMargin(estimatedGas),
        })
      })

      if (receipt?.status) {
        toastSuccess('Collect rewards successfully')
        await handleSuccess()
        await refreshBeanRewards()
      } else {
        setLoading(false)
      }
    } catch (error: any) {
      setLoading(false)
      toastError(error?.message || error?.data?.message || 'Collect rewards has error')
    }
  }

  const handleHatchBones = async () => {
    try {
      setLoading(true)
      const estimatedGas = await contractMethod.estimateGas.hatchBones(account)

      const receipt = await fetchWithCatchTxError(() => {
        return callWithGasPrice(contractMethod, 'hatchBones', [account], {
          gasPrice: DEFAULT_GAS_PRICE,
          gasLimit: calculateGasMargin(estimatedGas),
        })
      })

      if (receipt?.status) {
        toastSuccess('Compound Miners successfully')
        await handleSuccess()
        await refreshMyMiner()
      } else {
        setLoading(false)
      }
    } catch (error: any) {
      setLoading(false)
      toastError(error?.message || error?.data?.message || 'Compound Miners has error')
    }
  }

  useEffect(() => {
    getMinBunny((res) => setMinBunny(res))
  }, [getMinBunny])

  useEffect(() => {
    const calculateBoneBuy = async () => {
      try {
        const _calculateBoneBuy = await contractMethod.calculateBoneBuy(toLocaleString(1e18), toLocaleString(+bones))

        const result = (0.825 * +myMiner) / (+_calculateBoneBuy / 1080000) / 24

        setMiningSpeed(result)
      } catch (error) {
        console.log('🚀 ~ calculateBoneBuy ~ error:', error)
      }
    }

    if (!contractMethod || !bones || !myMiner) return

    calculateBoneBuy()
  }, [contractMethod, bones, myMiner])

  return (
    <Wrapper>
      <img src="/images/mining/start-mine-icon.png" alt="icon" className="top-logo" />

      <MiningContent>
        <div className="container">
          <div className="title-block">
            <div className="title">{t('START MINE TO EARN')}</div>
            <div>{t('The most advanced mining Rabbit on Binance Smart Chain.')}</div>
          </div>
        </div>
        <div className="container">
          <div className="mine-block">
            <div className="left-block">
              <div className="inner-block balance">
                <img src="/images/mining/bnb-coin.png" alt="bnb-coin" />
                <div className="balances">
                  <div className="balance-item">
                    <div>{t('Contract Balance')}</div>
                    <div style={{ wordBreak: 'break-all' }}>{bones ? (+bones / 1e18).toFixed(5) : 0} BNB</div>
                  </div>
                  <div className="balance-item">
                    <div>{t('Wallet Balance')}</div>
                    <div style={{ wordBreak: 'break-all' }}>{balance ? (+balance / 1e18).toFixed(5) : 0} BNB</div>
                  </div>
                </div>
              </div>
              <div className="inner-block how-to">
                <img src="/images/mining/how-to-mine-icon.png" alt="how-to-mine" />
                <div className="label">{t('How to mine')}</div>
                <div className="steps">
                  <div className="step">
                    <div className="bullet">1</div>
                    <div>
                      {t('Hold at least')} 200 $BUNNY {t('in your wallet')}
                    </div>
                  </div>
                  <div className="step">
                    <div className="bullet">2</div>
                    <div>{t('Hire Miners using BNB.')}</div>
                  </div>
                  <div className="step">
                    <div className="bullet">3</div>
                    <div>{t('You can compound miners using Compound Miners button.')}</div>
                  </div>
                  <div className="step">
                    <div className="bullet">4</div>
                    <div>{t('Click Collect Rewards to withdraw BNB collected')}</div>
                  </div>
                </div>
              </div>
              <div className="inner-block connect-wallet">
                <button onClick={toggleWallet}>
                  <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M25.8332 9.33333H24.4998V8C24.4998 6.93913 24.0784 5.92172 23.3283 5.17157C22.5781 4.42143 21.5607 4 20.4998 4H7.1665C6.10564 4 5.08822 4.42143 4.33808 5.17157C3.58793 5.92172 3.1665 6.93913 3.1665 8V24C3.1665 25.0609 3.58793 26.0783 4.33808 26.8284C5.08822 27.5786 6.10564 28 7.1665 28H25.8332C26.894 28 27.9115 27.5786 28.6616 26.8284C29.4117 26.0783 29.8332 25.0609 29.8332 24V13.3333C29.8332 12.2725 29.4117 11.2551 28.6616 10.5049C27.9115 9.75476 26.894 9.33333 25.8332 9.33333ZM7.1665 6.66667H20.4998C20.8535 6.66667 21.1926 6.80714 21.4426 7.05719C21.6927 7.30724 21.8332 7.64638 21.8332 8V9.33333H7.1665C6.81288 9.33333 6.47374 9.19286 6.2237 8.94281C5.97365 8.69276 5.83317 8.35362 5.83317 8C5.83317 7.64638 5.97365 7.30724 6.2237 7.05719C6.47374 6.80714 6.81288 6.66667 7.1665 6.66667ZM27.1665 20H25.8332C25.4795 20 25.1404 19.8595 24.8904 19.6095C24.6403 19.3594 24.4998 19.0203 24.4998 18.6667C24.4998 18.313 24.6403 17.9739 24.8904 17.7239C25.1404 17.4738 25.4795 17.3333 25.8332 17.3333H27.1665V20ZM27.1665 14.6667H25.8332C24.7723 14.6667 23.7549 15.0881 23.0047 15.8382C22.2546 16.5884 21.8332 17.6058 21.8332 18.6667C21.8332 19.7275 22.2546 20.7449 23.0047 21.4951C23.7549 22.2452 24.7723 22.6667 25.8332 22.6667H27.1665V24C27.1665 24.3536 27.026 24.6928 26.776 24.9428C26.5259 25.1929 26.1868 25.3333 25.8332 25.3333H7.1665C6.81288 25.3333 6.47374 25.1929 6.2237 24.9428C5.97365 24.6928 5.83317 24.3536 5.83317 24V11.7733C6.26153 11.924 6.71242 12.0007 7.1665 12H25.8332C26.1868 12 26.5259 12.1405 26.776 12.3905C27.026 12.6406 27.1665 12.9797 27.1665 13.3333V14.6667Z"
                      fill="black"
                    />
                  </svg>
                  {!account ? t('Connect Wallet') : formatCode(account, 5, 5)}
                </button>
              </div>
            </div>
            <div className="right-block">
              <div className="mine-form">
                <div className="min-max">
                  <div>
                    {t('Min')} <b>0,01 BNB</b>
                  </div>
                  <svg width="152" height="6" viewBox="0 0 152 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M151.25 3L146.25 0.113249V5.88675L151.25 3ZM0.75 3.5H146.75V2.5H0.75V3.5Z" fill="black" />
                  </svg>
                  <div>
                    {t('Max')} <b>200 BNB</b>
                  </div>
                </div>
                <input
                  type="number"
                  placeholder="0.0"
                  onChange={handleChangeAmount}
                  value={amount}
                  disabled={!account}
                />
                <div className="tip-block">
                  <div className="tip">{t('TIP')}</div>
                  <div>{t('Enter BNB amount & click buy below')}</div>
                </div>
                <Button
                  loading={loading || (account && loadingRef)}
                  disabled={renderInputStatus() !== 'Hire miners'}
                  onClick={handleBuyBones}
                >
                  {t(renderInputStatus())}
                </Button>
              </div>
              <div className="reward-buttons">
                <Button loading={loading} disabled={!account || !myMiner} onClick={handleHatchBones}>
                  {t('Compound Miners')}
                </Button>
                <Button loading={loading} disabled={!account || !beanRewards} onClick={handleSellBones}>
                  {t('Collect Rewards')}
                </Button>
              </div>
              <div className="profit">
                <div>
                  <div>{t('DAILY PROFIT')}</div>
                  <div>
                    <span>{t('UP TO')}</span> <b>8%</b>
                  </div>
                </div>
                <div>
                  <div>{t('TAX')}</div>
                  <div>
                    <b>4%</b>
                  </div>
                </div>
              </div>
              <div className="summary">
                <div>
                  <img src="/images/mining/summary-miners.png" alt="miners" />
                  <div>{t('Miners')}</div>
                  <div>{myMiner ? +myMiner : 0}</div>
                </div>
                <div>
                  <img src="/images/mining/summary-speed.png" alt="speed" />
                  <div>{t('Mining Speed')}</div>
                  <div style={{ flexDirection: 'row', justifyContent: 'flex-start' }}>
                    {miningSpeed ? <FormatNumberSmall number={+miningSpeed} /> : 0}BNB /24H
                  </div>
                </div>
                <div>
                  <img src="/images/mining/summary-reward.png" alt="reward" />
                  <div>{t('My Reward')}</div>
                  <div style={{ flexDirection: 'row', justifyContent: 'flex-start' }}>
                    {beanRewards ? <FormatNumberSmall number={+beanRewards / 1e18} /> : 0} BNB
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </MiningContent>
    </Wrapper>
  )
}

export default StartMine
