/* eslint-disable no-restricted-syntax */
/* eslint-disable react/react-in-jsx-scope */
import { useEffect, useState } from 'react'
import { formatNumber } from 'utils/formatBalance'

export default function FormatNumberSmall({ number }) {
  const stringValue = number.toString()
  const [firstPart, secondPart] = stringValue.split('.')
  const [checkCount, setCheckCount] = useState(0)

  useEffect(() => {
    let count = 0

    if (secondPart?.includes('e-')) {
      const result = secondPart?.split('e-')[1]
      setCheckCount(+result)
      return
    }

    for (const char of secondPart) {
      if (+char === 0) {
        count += 1
      } else {
        setCheckCount(count)
        break
      }
    }
  }, [secondPart])

  return (
    <>
      {checkCount > 2 ? (
        <span>
          {secondPart?.includes('e-') ? 0 : firstPart}.0<sub style={{ fontSize: 12 }}>{checkCount}</sub>
          {secondPart?.includes('e-') ? stringValue[0] : secondPart?.slice(checkCount, checkCount + 2)}
        </span>
      ) : (
        formatNumber(number, 4, 4)
      )}
    </>
  )
}
