import styled from 'styled-components'

export const MiningContent = styled.div`
  width: 100%;
  position: relative;

  &:before,
  &:after {
    content: '';
    position: absolute;
    left: -10%;
    width: 120%;
    height: calc(80% + 100px);
    max-height: 360px;
    background-color: #015cf7;
    border-left: 12px solid #b1ffff;
    border-right: 12px solid #b1ffff;

    ${({ theme }) => theme.mediaQueries.md} {
      max-height: 720px;
    }
  }

  &:before {
    border-radius: 100% 100% 0 0;
    top: -100px;
    border-top: 12px solid #b1ffff;
  }

  &:after {
    border-radius: 0 0 100% 100%;
    bottom: -100px;
    border-bottom: 12px solid #b1ffff;
  }

  b {
    vertical-align: top;
    display: inline-block;
  }

  .title-block {
    text-align: center;
    max-width: 300px;
    margin: 0 auto;

    .title {
      font-family: Death Star;
      font-size: 28px;
      font-weight: 400;
      line-height: 48px;
      letter-spacing: 0.01em;
      margin-bottom: 1rem;

      ${({ theme }) => theme.mediaQueries.md} {
        font-size: 42px;
      }

      ${({ theme }) => theme.mediaQueries.lg} {
        font-size: 52px;
      }
    }
  }

  .container:has(.mine-block) {
    background-color: #015cf7;
  }

  .mine-block {
    margin: 2rem 0;
    border-radius: 12px;
    padding: 14px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    gap: 24px;

    ${({ theme }) => theme.mediaQueries.lg} {
      padding: 24px;
      flex-direction: row;
      margin-bottom: 6rem;
    }

    .left-block,
    .right-block {
      border-radius: 12px;
      padding: 12px 14px;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      flex: 1;
      box-shadow: 0 0 32px rgba(0, 0, 0, 0.1);

      ${({ theme }) => theme.mediaQueries.md} {
        padding: 22px 24px;
      }
    }

    .left-block {
      background-color: #b7f6ff;
    }

    .right-block {
      justify-content: space-between;
      background-color: #fff;

      .min-max {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 1rem;
        font-family: Clash Display;
        font-size: 16px;
        font-weight: 500;
        line-height: 1.5;
        color: #000;
        text-align: center;

        ${({ theme }) => theme.mediaQueries.md} {
          font-size: 24px;
          text-align: left;
        }
      }

      .mine-form {
        display: flex;
        flex-direction: column;
        gap: 1rem;

        input {
          border: 1px solid #c9c9c9;
          border-radius: 8px;
          padding: 12px;
          background-color: #f5f5f5;
          font-family: Clash Display;
          font-size: 20px;
          font-weight: 600;
          line-height: 1;
          text-align: left;
          outline: none;
          color: #000;

          ${({ theme }) => theme.mediaQueries.md} {
            padding: 12px 24px;
            font-size: 32px;
          }

          :disabled {
            background-color: #ccc;
          }
        }

        .tip-block {
          background-color: #f0ffff;
          border-radius: 100px;
          display: flex;
          gap: 1rem;
          align-items: center;
          font-family: Clash Display;
          font-size: 14px;
          font-weight: 600;
          line-height: 1.5;
          text-align: left;
          color: #000;

          ${({ theme }) => theme.mediaQueries.md} {
            font-size: 18px;
          }

          .tip {
            font-family: Clash Display;
            font-size: 12px;
            font-weight: 700;
            line-height: 1.2;
            letter-spacing: 0.12em;
            text-align: left;
            color: #fff;
            padding: 6px 8px;
            background-color: #000;
            border-radius: 100px;

            ${({ theme }) => theme.mediaQueries.md} {
              padding: 6px 12px;
              font-size: 16px;
            }
          }
        }

        button {
          border: 1px solid #015cf7;
          border-radius: 8px;
          padding: 12px;
          background-color: #e0ebfe;
          font-family: Clash Display;
          font-size: 16px;
          font-weight: 500;
          line-height: 1.3;
          text-align: center;
          outline: none;
          color: #000;

          ${({ theme }) => theme.mediaQueries.md} {
            padding: 12px 24px;
            font-size: 24px;
          }

          :disabled {
            background-color: #ccc;
          }
        }
      }

      .reward-buttons {
        display: flex;
        gap: 0.5rem;
        justify-content: center;

        ${({ theme }) => theme.mediaQueries.md} {
          gap: 1rem;
        }

        button {
          flex: 1;
          border: 1px solid #015cf7;
          border-radius: 8px;
          padding: 12px 8px;
          background-color: #015cf7;
          font-family: Clash Display;
          font-size: 13px;
          font-weight: 500;
          line-height: 1.3;
          text-align: center;
          outline: none;
          color: #fff;

          ${({ theme }) => theme.mediaQueries.md} {
            padding: 12px 24px;
            font-size: 20px;
          }

          :disabled {
            background-color: #ccc;
          }
        }
      }

      .profit {
        padding: 12px;
        border-radius: 8px;
        border: 1px solid #e1e1e1;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        justify-content: space-between;
        color: #000;

        ${({ theme }) => theme.mediaQueries.md} {
          padding: 12px 24px;
          flex-direction: row;
        }

        div {
          flex: 1;
          font-family: Clash Display;
          font-size: 16px;
          font-weight: 400;
          line-height: 1.5;
          text-align: left;

          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 6px;

          ${({ theme }) => theme.mediaQueries.md} {
            flex-direction: column;
            align-items: flex-start;
          }

          > div {
            display: flex;
            align-items: center;
            flex-direction: row;
          }

          b {
            font-family: Clash Display;
            font-size: 20px;
            font-weight: 600;
            line-height: 1.3;
            text-align: left;
            vertical-align: top;

            ${({ theme }) => theme.mediaQueries.md} {
              font-size: 24px;
            }
          }

          span {
            font-family: Clash Display;
            font-size: 16px;
            font-weight: 500;
            line-height: 32px;
            text-align: left;
            display: inline-block;
          }
        }
      }

      .summary {
        border-radius: 12px;
        padding: 12px 16px;
        background: linear-gradient(324.87deg, #ff8e25 10.68%, #ffde67 82.39%);
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        gap: 1rem;
        color: #000;
        position: relative;

        ${({ theme }) => theme.mediaQueries.md} {
          flex-direction: row;
          padding: 12px 30px;
        }

        &:before,
        &:after {
          content: '';
          position: absolute;
          width: 32px;
          height: 32px;
          background-color: #fff;
          top: 50%;
          transform: translateY(-50%);
        }

        &:before {
          left: -16px;
          border-radius: 100%;
        }

        &:after {
          right: -16px;
          border-radius: 100%;
        }

        div {
          width: 100%;
          display: flex;
          gap: 0.5rem;
          align-items: center;
          justify-content: space-between;

          ${({ theme }) => theme.mediaQueries.md} {
            width: fit-content;
            flex-direction: column;
          }

          img {
            width: 30px;
            flex-shrink: 0;

            ${({ theme }) => theme.mediaQueries.md} {
              width: 46px;
            }
          }

          div {
            font-family: Clash Display;
            font-size: 14px;
            font-weight: 500;
            line-height: 1.5;

            ${({ theme }) => theme.mediaQueries.xs} {
              width: 100px;
            }

            ${({ theme }) => theme.mediaQueries.md} {
              text-align: center;
              font-size: 18px;
              width: auto;
            }
          }

          div:last-child {
            font-family: Clash Display;
            font-size: 14px;
            font-weight: 600;
            line-height: 1.5;

            ${({ theme }) => theme.mediaQueries.xs} {
              flex: 1;
            }

            ${({ theme }) => theme.mediaQueries.md} {
              text-align: center;
              font-size: 18px;
            }

            ${({ theme }) => theme.mediaQueries.xl} {
              font-size: 20px;
            }
          }
        }
      }
    }

    .inner-block {
      border-radius: 12px;
      padding: 16px 20px;

      &.balance {
        background-color: #faffd5;
        display: flex;
        align-items: center;
        gap: 24px;
        flex-direction: column;

        ${({ theme }) => theme.mediaQueries.md} {
          flex-direction: row;
        }

        img {
          width: 154px;
        }

        .balances {
          color: #000;
          width: 100%;

          .balance-item {
            &:not(:last-child) {
              padding-bottom: 0.5rem;
            }

            &:not(:first-child) {
              border-top: 1px solid #c4c4c4;
              padding-top: 0.5rem;
            }

            div:first-child {
              font-family: Clash Display;
              font-size: 16px;
              font-weight: 500;
              line-height: 1.5;
            }

            div:last-child {
              font-family: Clash Display;
              font-size: 20px;
              font-weight: 600;
              line-height: 1.5;

              ${({ theme }) => theme.mediaQueries.md} {
                font-size: 28px;
              }
            }
          }
        }
      }

      &.how-to {
        background-color: #e4ffff;
        color: #000;
        position: relative;

        img {
          position: absolute;
          width: 62px;
          top: 12px;
          right: 24px;
          z-index: 0;
        }

        .label {
          font-family: Clash Display;
          font-size: 24px;
          font-weight: 700;
          line-height: 1;
          text-align: left;
          margin-bottom: 2rem;
          margin-top: 1rem;
          position: relative;

          ${({ theme }) => theme.mediaQueries.md} {
            font-size: 32px;
          }
        }

        .steps {
          display: flex;
          flex-direction: column;
          gap: 0.5rem;

          .step {
            display: flex;
            gap: 1rem;
            align-items: center;
            font-family: Clash Display;
            font-size: 14px;
            font-weight: 500;
            line-height: 1.5;
            text-align: left;

            ${({ theme }) => theme.mediaQueries.md} {
              font-size: 16px;
            }

            .bullet {
              flex-shrink: 0;
              width: 34px;
              height: 34px;
              border-radius: 34px;
              display: flex;
              justify-content: center;
              align-items: center;
              font-family: Clash Display;
              font-size: 16px;
              font-weight: 600;
              line-height: 1.5;
              text-align: left;
              color: #fff;
              background-color: #000;

              ${({ theme }) => theme.mediaQueries.md} {
                font-size: 20px;
              }
            }
          }
        }
      }

      &.connect-wallet {
        background-color: #e4ffff;
        color: #000;
        padding: 10px;

        button {
          background-color: #ffd100;
          font-family: Clash Display;
          font-size: 24px;
          font-weight: 600;
          line-height: 32px;
          text-align: center;
          outline: none;
          border: none;
          border-radius: 12px;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 0.5rem;
          width: 100%;
          padding: 12px;

          ${({ theme }) => theme.mediaQueries.md} {
            padding: 12px 24px;
          }
        }
      }
    }
  }
`
