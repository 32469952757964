import { useMiningContract } from 'hooks/useContract'
import { useCallback } from 'react'
import useSWR from 'swr'

const useGetBeanRewards = (address: string): any => {
  const contractMethod = useMiningContract()

  const getData = useCallback(async () => {
    if (contractMethod && address) {
      try {
        const beanRewards = await contractMethod.beanRewards(address)
        if (beanRewards) return beanRewards
      } catch (error) {
        console.error('useGetBeanRewards', error)
        return null
      }
    }
  }, [contractMethod, address])

  const { data, mutate } = useSWR(
    [address, 'getBeanRewards'],
    () => {
      return getData()
    },
    {
      refreshInterval: 15000,
      revalidateOnFocus: false,
    },
  )

  return { data, refresh: mutate }
}

export default useGetBeanRewards
